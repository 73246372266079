<template>
  <div id="login">
    <!-- Войти / Зарегистрироваться -->
    <div class="container p-4" style="width: 30rem">
      <ul class="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="tab-login"
            data-mdb-toggle="pill"
            href="#pills-login"
            role="tab"
            aria-controls="pills-login"
            aria-selected="true"
            >Войти</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="tab-register"
            data-mdb-toggle="pill"
            href="#pills-register"
            role="tab"
            aria-controls="pills-register"
            aria-selected="false"
            >Зарегистрироваться</a
          >
        </li>
      </ul>

      <div class="tab-content">
        <!-- Войти -->
        <div
          class="tab-pane fade show active"
          id="pills-login"
          role="tabpanel"
          aria-labelledby="tab-login"
        >
          <form action="#" method="post" enctype="multipart/form-data">
            <input
              type="email"
              placeholder="Электронная почта"
              id="EmailLogin"
              class="log-field form-control-dark mb-4"
              v-model="emailLogin"
              style="width: 27rem"
            />

            <input
              type="password"
              placeholder="Пароль"
              id="PasswordLogin"
              class="log-field form-control-dark mb-4"
              v-model="passwordLogin"
              style="width: 27rem"
            />

            <input
              type="button"
              class="login-btn btn btn-dark btn-block mb-4"
              @click="login"
              value="Войти"
            />
          </form>

          <p class="text-danger" v-if="msg">{{ msg }}</p>

          <div class="col under-log">
            <router-link class="text-muted" to="/auth/forgot"
              >Забыли пароль?</router-link
            >
          </div>
        </div>

        <!-- Зарегистрироваться -->
        <div
          class="tab-pane fade"
          id="pills-register"
          role="tabpanel"
          aria-labelledby="tab-register"
        >
          <form action="#" method="post" enctype="multipart/form-data">
            <input
              type="text"
              placeholder="Имя"
              id="Firstname"
              class="log-field form-control-dark mb-4"
              v-model="firstname"
              style="width: 27rem"
            />

            <input
              type="text"
              placeholder="Фамилия"
              id="Lastname"
              class="log-field form-control-dark mb-4"
              v-model="lastname"
              style="width: 27rem"
            />

            <input
              type="email"
              placeholder="Электронная почта"
              id="Email"
              class="log-field form-control-dark mb-4"
              v-model="email"
              style="width: 27rem"
            />
            <input
              type="text"
              placeholder="Телефон"
              id="tel"
              class="log-field form-control-dark mb-4"
              v-model="tel"
              style="width: 27rem"
              @keyup="validatePhoneNumber"
              :class="{
                valid: isValidPhoneNumber,
                invalid: !isValidPhoneNumber,
              }"
            />
            <input
              type="tg"
              placeholder="Ник в телеграм"
              id="tg"
              class="log-field form-control-dark mb-4"
              v-model="tg"
              style="width: 27rem"
              :class="{ valid: tg !== '', invalid: tg === '' }"
            />

            <input
              type="password"
              placeholder="Пароль"
              id="Password"
              class="log-field form-control-dark mb-4"
              v-model="password"
              style="width: 27rem"
            />

            <input
              type="password"
              placeholder="Пароль еще раз"
              id="Password_repeat"
              class="log-field form-control-dark mb-4"
              v-model="password_repeat"
              style="width: 27rem"
            />
            <div class="checkbox_agreement">
              <input
                type="checkbox"
                id="personal_checkbox"
                name="personal_checkbox"
                v-model="personal_checkbox"
                style="width: 24.4px !important;"
              />
              <label for="personal_checkbox"
                >Согласен на
                <a href="https://www.forus.ru/files/pd_npf.pdf" target="_blank"
                  >обработку персональных данных</a
                ></label
              >
            </div>

            <div class="checkbox_agreement">
              <input
                type="checkbox"
                id="advert_checkbox"
                name="advert_checkbox"
                v-model="advert_checkbox"
                style="width: 24.4px !important;"
              />
              <label for="advert_checkbox"
                >Согласен на получение рекламных рассылок</label
              >
            </div>
            <input
              type="button"
              class="login-btn btn btn-dark btn-block mb-4"
              @click="signUp"
              :disabled="!isValidPhoneNumber && !personal_checkbox"
              value="Зарегистрироваться"
            />
          </form>
          <p class="text-danger" v-if="msg">{{ msg }}</p>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGradientInside from "@/components/Landing/ButtonInsideGradient";

import { login, signUp } from "@/api/auth.js";
import { mapGetters, mapMutations } from "vuex";
import { findById } from "@/api/user.js";
export default {
  components: { ButtonGradientInside },
  data() {
    return {
      isValidPhoneNumber: false,
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      emailLogin: "",
      passwordLogin: "",
      password_repeat: "",
      msg: "",
      tel: "",
      tg: "",
      advert_checkbox: true,
      personal_checkbox: true,
    };
  },
  mounted() {
    if (window.location.href.includes("#pills-register")) {
      document.querySelector("#tab-register").click();
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    async signUp() {
      try {
        const credentials = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          password_repeat: this.password_repeat,
          tel: this.tel,
          tg: this.tg,
          advert_agreement: this.advert_checkbox,
          personal_agreement: this.personal_checkbox,
        };
        const response = await signUp(credentials);
        const token = response.accessToken;
        const user = response.user;
        this.$store.dispatch("auth/login", { token, user });
        this.getUser(user.id);
      } catch (error) {
        this.msg = error.response.data.message;
      }
    },
    validatePhoneNumber() {
      const validationRegex = /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g;
      if (this.tel.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    getUser(id) {
      findById(id).then((response) => {
        this.SET_USER(response.data);
        if (
          response.data.hasOwnProperty("organization") &&
          response.data.organization.length > 0
        ) {
          this.$router.push("/organization/profile");
        } else {
          this.$router.push("/beta/profile");
        }
      });
    },
    async login() {
      try {
        const credentials = {
          email: this.emailLogin,
          password: this.passwordLogin,
        };
        const response = await login(credentials);
        // this.msg = response;
        const token = response.token;
        const user = response.user;
        this.$store.dispatch("auth/login", { token, user });
        this.getUser(user.id);
      } catch (error) {
        this.msg = error.response.data.message;
      }
    },
  },
};
</script>

<style scoped>
.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.invalid {
  border: 1px solid rgba(238, 74, 74, 0.651) !important;
}
.valid {
  border: 1px solid rgba(0, 128, 0, 0.537) !important;
}

.radio-option {
  margin: 0px 0px 20px 0px;
}
.radio-option input {
  margin-right: 10px;
}
.nav-item > .active {
  font-weight: 600 !important;
}
.nav-link:focus {
  font-weight: 600;
}

.under-log {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-muted{
  color: #e8e7ec !important;
}

.text-muted:hover {
  border-bottom: 2px solid rgba(232, 231, 236, 0.15);
}

.auth-btn {
  background-color: transparent;
  padding: 5px;
  border: none;
}

.auth-btn:hover {
  color: #d64a4a;
}

.auth-btn > i {
  color: #d64a4a;
}

.log-field {
  color: black;
  border: 1px solid #bdbdbd78;
  border-radius: 14px !important;
  padding: 8px 10px;
}

.log-field:focus-visible {
  outline: none !important;
}
#login {
  font-family: "Montserrat";
  background-color: transparent !important;
  background-image: url("../../assets/img/_landing/karakul2.svg") !important;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: 30% !important;
  z-index: 0;
  margin-top: 80px;
  min-height: 95vh;
}
.login-btn {
  text-transform: capitalize;
  color: black;
  padding: 0.75rem 3rem;
  height: auto !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 14px !important;
  background-color: #ffcf66;
  font-weight: 600;

  margin-top: 1.5rem;
}
</style>
